import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { Button } from '@mui/material';

import Container from '../../../../components/Container';
import DayBG from '../../../../assets/SplashPageDay.jpg';
import NightBG from '../../../../assets/SplashPageNight.jpg';

const Hero = () => {
  const theme = useTheme();

  return (
    <Box
          style={{ backgroundColor: theme.palette.mode === 'dark' ? "#111111" : "#153194", backgroundImage: theme.palette.mode === 'dark' ? `url(${NightBG})` : `url(${DayBG})`, backgroundSize: '90%', backgroundRepeat:"no-repeat", backgroundPositionX: "center"}}
    >
      <Box paddingY={{ xs: 0, sm: '4rem', md: '8rem' }}>
        <Container>
          <Box maxWidth={{ xs: 1, sm: '50%' }}>
            <Typography
              variant="h2"
              color="white"
              gutterBottom
              sx={{
                fontWeight: 700,
              }}
            >
              Locally owned 
              <br />
              Pest Control
            </Typography>
            <Typography
              variant="h6"
              component="p"
              color="white"
              sx={{ fontWeight: 400 }}
            >
              With over 30 years of experience serving Merced County.
                      </Typography>
                      <Button variant="contained" href="/contact-us" style={{ backgroundColor: "#339933", fontWeight: 900} }>Schedule Service Today!</Button>
          </Box>
        </Container>
    </Box>
      <Box
        component={'svg'}
        preserveAspectRatio="none"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 1920 100.1"
        sx={{
          width: '100%',
          marginBottom: theme.spacing(-1),
        }}
      >
        <path
          fill={theme.palette.background.paper}
          d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
        ></path>
      </Box>
    </Box>
  );
};

export default Hero;
