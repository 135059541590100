const pages = {
    home: [
        {
            title: 'Home',
            href: '/',
        },
/*        {
            title: 'Testimonials',
            href: '/testimonials',
        },*/
        {
            title: 'About Us',
            href: '/about-us',
        },
/*        {
            title: 'FAQ',
            href: '/faq',
        },*/
        {
            title: 'Coverage Map',
            href: '/coverage-map'
        },
        {
            title: 'Contact Us',
            href: '/contact-us'
        }
    ],
};

export default pages;
