import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { alpha, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import logo from "../../../../assets/Logo.png";
import logoInvert from "../../../../assets/InvertLogo.png";

import ThemeModeToggler from '../../../../components/ThemeModeToggler';

import { NavItem } from './components';

const Topbar = ({ onSidebarOpen, pages, colorInvert = false }) => {
  const theme = useTheme();
  const { mode } = theme.palette;
  const linkColor = colorInvert ? 'common.white' : 'text.primary';
  const {
    home: homePages,
  } = pages;

    const [activeLink, setActiveLink] = useState('');
    useEffect(() => {
        setActiveLink(window && window.location ? window.location.pathname : '');
    }, []);

  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      width={1}
    >
      <Box
              display={'flex'}
              component="a"
              href="/"
              title="Exodus Pest Control"
              width={{ xs: 100, md: 120 }}
              height={{xs: 50, md: 75}}
      >
        <Box
          component={'img'}
          src={
            mode === 'light' && !colorInvert
              ? logo
              : logoInvert
          }
          height={1}
        />
      </Box>
          <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: "center" }} alignItems={'center'}>
              {homePages.map((p, i) => (
                  <Box marginLeft={2} key={i}>
                      <Button
                          display={'flex'}
                          component={'a'}
                          sx={{
                              color:
                                  activeLink === p.href ? theme.palette.primary.main
                                      : theme.palette.text.primary,
                              cursor: 'pointer',
                          }}
                          href={p.href}
                      >
                          <Typography
                              fontWeight={activeLink === p.href ? 700 : 400}
                              color={linkColor}
                          >
                              {p.title}
                          </Typography>
                      </Button>
                  </Box>
                  ))}
        {/*<Box marginLeft={4}>
          <NavItem
            title={'About Us'}
            id={'aboutPages'}
            items={aboutPages}
            colorInvert={colorInvert}
          />
        </Box>*/}
        <Box marginLeft={3}>
            <ThemeModeToggler />
        </Box>

      </Box>
      <Box sx={{ display: { xs: 'block', md: 'none' } }} alignItems={'center'}>
        <Button
          onClick={() => onSidebarOpen()}
          aria-label="Menu"
          variant={'outlined'}
          sx={{
            borderRadius: 2,
            minWidth: 'auto',
            padding: 1,
            borderColor: alpha(theme.palette.divider, 0.2),
          }}
        >
          <MenuIcon />
        </Button>
      </Box>
    </Box>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
  pages: PropTypes.object,
  colorInvert: PropTypes.bool,
};

export default Topbar;
