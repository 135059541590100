import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

import NavItem from './components/NavItem';
import logo from "../../../../../../assets/Logo.png";
import ThemeModeToggler from '../../../../../../components/ThemeModeToggler';

const SidebarNav = ({ pages }) => {
  const theme = useTheme();
  const { mode } = theme.palette;

    const {
        home: homePages,
    } = pages;

    const [activeLink, setActiveLink] = useState('');
    useEffect(() => {
        setActiveLink(window && window.location ? window.location.pathname : '');
    }, []);

  return (
    <Box>
      <Box width={1} paddingX={2} paddingY={1}>
        <Box
          display={'flex'}
          component="a"
          href="/"
          title="Exodus Pest Control"
          width={{ xs: 100, md: 120 }}
        >
          <Box
            component={'img'}
            src={logo}
            height={1}
            width={1}
          />
        </Box>
      </Box>
      <Box sx={{ alignItems: "center" }} alignItems={'center'}>
              {homePages.map((p, i) => (
                  <Box marginLeft={2} key={i}>
                      <Button
                          display={'flex'}
                          component={'a'}
                          sx={{
                              color:
                                  activeLink === p.href ? theme.palette.primary.main
                                      : theme.palette.text.primary,
                              cursor: 'pointer',
                          }}
                          href={p.href}
                      >
                          <Typography
                              fontWeight={activeLink === p.href ? 700 : 400}
                              color={theme.palette.text.primary}
                          >
                              {p.title}
                          </Typography>
                      </Button>
                  </Box>
                  ))}
        {/*<Box marginLeft={4}>
          <NavItem
            title={'About Us'}
            id={'aboutPages'}
            items={aboutPages}
            colorInvert={colorInvert}
          />
        </Box>*/}
        <Box marginLeft={3}>
            <ThemeModeToggler />
        </Box>
      </Box>
    </Box>
  );
};

SidebarNav.propTypes = {
  pages: PropTypes.object.isRequired,
};

export default SidebarNav;
