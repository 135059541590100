/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';

import { ReactComponent as World} from '../../../../assets/world.svg';
import { ReactComponent as Vermin } from '../../../../assets/rat-exterm.svg';
import { ReactComponent as Time } from '../../../../assets/time.svg';

const mock = [
  {
    title: 'Responsible Treatments',
    subtitle:
      'No Pesticide is truly "Eco-Friendly", but we have the knowledge and skillset to eliminate risk. We\'re dedicated to eliminating potential harm to you, your property, and the environment all while providing exceptional service.',
    icon: (
        <svg><World fill="#339933" width="100%" height="100%" /></svg>
    ),
  },
  {
    title: 'No Bug Is Too Tough',
    subtitle:
      'If you\'ve got a pest problem, we\'ve got the solution! We treat for Rats, Wasps, Roaches, Mice, Ants, Water Bugs, Yellow Jackets, Spiders, Silverfish, Earwigs, Ticks, and More...',
    icon: (
        <svg ><Vermin fill="#339933" width="100%" height="100%" /></svg>
    ),
  },
  {
    title: 'Prompt And Professional',
    subtitle:
      'We\'re licensed and bonded and have over 30 years of experience in the industry. Rain or Shine, we\'ll be there to help you out!',
    icon: (
        <svg><Time stroke="#339933" width="100%" height="100%" /></svg>
    ),
  },
];

const Services = () => {
  const theme = useTheme();
  return (
    <Box>
      <Box marginBottom={4}>
        <Box marginBottom={2}>
          <Typography
            variant="h4"
            color="text.primary"
            align={'center'}
            gutterBottom
            sx={{
              fontWeight: 700,
            }}
          >
                      Are pests taking over your home or business?
          </Typography>
          <Typography
            variant="h6"
            component="p"
            color="text.secondary"
            sx={{ fontWeight: 400 }}
            align={'center'}
          >
                      Don't let unwanted guests ruin your property and harm your health. Let our pest control experts handle it for you!
          </Typography>
        </Box>
      </Box>
      <Grid container spacing={2}>
        {mock.map((item, i) => (
          <Grid item xs={12} md={4} key={i}>
            <Box width={1} height={1}>
              <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
              >
                <Box
                  component={Avatar}
                  width={60}
                  height={60}
                  marginBottom={2}
                  bgcolor={alpha(theme.palette.primary.main, 0.1)}
                  color={theme.palette.primary.main}
                >
                  {item.icon}
                </Box>
                <Typography
                  variant={'h6'}
                  gutterBottom
                  sx={{ fontWeight: 500 }}
                  align={'center'}
                >
                  {item.title}
                </Typography>
                <Typography align={'center'} color="text.secondary">
                  {item.subtitle}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Services;
