import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routes from './Routes';
import Page from './components/Page';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'aos/dist/aos.css';

function App() {
    return (
        <Page>
            <BrowserRouter>
                <Routes />
            </BrowserRouter>
        </Page>
  );
}

export default App;
