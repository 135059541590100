import React, { useRef, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { alpha, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Main from '../../layouts/Main';
import Container from '../../components/Container';

import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import "./CoverageMap.css";

mapboxgl.accessToken = 'pk.eyJ1IjoiZHN0b2ZmZWw5NiIsImEiOiJjbGVxZDRvcmQwbDJtM29udnVtdmMzZG92In0.8WU0vAN3vx0USTRRfP47Pg';

const CoverageMap = () => {
    const mapContainer = useRef(null);
    const theme = useTheme();

    const map = useRef(null);
    const [lng, setLng] = useState(-120.55);
    const [lat, setLat] = useState(37.338);
    const [zoom, setZoom] = useState(11);
    const checkIfInsideBoundary = (address) => {

    }

    useEffect(() => {
        if (map.current) return; // initialize map only once
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v12',
            center: [lng, lat],
            zoom: zoom,
            interactive: false
        });
        map.current.on('load', () => {
            // Add a data source containing GeoJSON data.
            map.current.addSource('merced', {
                'type': 'geojson',
                'data': {
                    'type': 'Feature',
                    'geometry': {
                        'type': 'Polygon',
                        'coordinates': [
                            [
                                [
                                    -120.64968804018943,
                                    37.40356810013108
                                ],
                                [
                                    -120.65124578430081,
                                    37.30220669963197
                                ],
                                [
                                    -120.61487137883,
                                    37.3021519269157
                                ],
                                [
                                    -120.6152538237541,
                                    37.280322861870246
                                ],
                                [
                                    -120.42294669915171,
                                    37.27998126191305
                                ],
                                [
                                    -120.42267191993716,
                                    37.33266233645817
                                ],
                                [
                                    -120.43241991204013,
                                    37.33244297103006
                                ],
                                [
                                    -120.43194573228931,
                                    37.36140130437609
                                ],
                                [
                                    -120.46829365805992,
                                    37.36131873217934
                                ],
                                [
                                    -120.46675580879834,
                                    37.392195700728934
                                ],
                                [
                                    -120.5760118933946,
                                    37.40400471463279
                                ],
                                [
                                    -120.64968804018943,
                                    37.40356810013108
                                ]
                            ]
                        ]
                    }
                }
            });

            // Add a new layer to visualize the polygon.
            map.current.addLayer({
                'id': 'merced',
                'type': 'fill',
                'source': 'merced', // reference the data source
                'layout': {},
                'paint': {
                    'fill-color': '#0080ff', // blue color fill
                    'fill-opacity': 0.1
                }
            });
            // Add a black outline around the polygon.
            map.current.addLayer({
                'id': 'outline',
                'type': 'line',
                'source': 'merced',
                'layout': {},
                'paint': {
                    'line-color': '#339933',
                    'line-width': 3
                }
            });
        });
    });
    return (
        <Box sx={{ overflowX: 'hidden' }}>
            <Main bgcolor={'background.paper'}>
                <Box
                    sx={{
                        backgroundImage: `linear-gradient(to bottom, ${alpha(
                            theme.palette.background.paper,
                            0,
                        )}, ${alpha(theme.palette.alternate.main, 1)} 100%)`,
                        backgroundRepeat: 'repeat-x',
                        textAlign: 'center',
                        paddingBottom: 2
                    }}
                >
                    <Typography variant={'h4'} fontWeight={900}>
                        Serving the greater Merced County Area
                    </Typography>
                </Box>
                <Container style={{padding: 0} }>
                    <div ref={mapContainer} className="map-container" />
                </Container>
            </Main>
        </Box>
    );
}
export default CoverageMap