import React from 'react';

import {
  IndexView,
  About as AboutView,
  Testimonials as TestimonialsView,
  Faq as FaqView,
    CoverageMap as CoverageMapView,
  ContactPage as ContactPageView,
  NotFound as NotFoundView,
  NotFoundCover as NotFoundCoverView
} from './';

const routes = [
  {
    path: '/',
    renderer: (params = {}) => <IndexView {...params} />,
  },
  {
    path: '/about-us',
      renderer: (params = {}) => <AboutView {...params} />,
  },
 /* {
    path: '/testimonials',
    renderer: (params = {}) => <TestimonialsView {...params} />,
    },*/
/*    {
        path: '/faq',
        renderer: (params = {}) => <FaqView {...params} />,
    },*/
    {
        path: '/coverage-map',
        renderer: (params = {}) => <CoverageMapView {...params} />,
    },
    {
        path: '/contact-us',
        renderer: (params = {}) => <ContactPageView {...params} />,
    },
  {
    path: '/not-found',
    renderer: (params = {}) => <NotFoundView {...params} />,
    },
  {
    path: '/not-found-cover',
    renderer: (params = {}) => <NotFoundCoverView {...params} />,
  },
];

export default routes;
